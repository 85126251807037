import React from 'react'
import PropTypes from 'prop-types'
import {colors, typography} from '@lookout/ui'

export function DoubleHeader({className, suppressAutoshift, ...props}) {
  let {top, bottom} = props
  if (!top && bottom && !suppressAutoshift) {
    top = bottom
    bottom = null
  }
  return (
    <div className={className} css={typography.blush}>
      {top && (
        <>
          <span css={{fontSize: 14}} className="double-header-top">
            {top}
          </span>
          <br />
        </>
      )}
      {bottom && (
        <span
          css={{fontSize: 12, color: colors.darkGray300}}
          className="double-header-bottom"
        >
          {bottom}
        </span>
      )}
    </div>
  )
}

DoubleHeader.propTypes = {
  top: PropTypes.node,
  bottom: PropTypes.node,
  className: PropTypes.string,
  suppressAutoshift: PropTypes.bool,
}
