import {
  colors,
  Button,
  Modal,
  Panel,
  PanelHeader,
  PanelFooter,
  Textarea,
} from '@lookout/ui'
import _ from 'lodash'
import React, {useMemo, useState, Suspense} from 'react'
import {useResourceOnce} from '@lookout/suspense'
import {JSONTree} from 'react-json-tree'
import jsonTreeTheme from '../../assets/json-tree-theme'
import {readDevice} from '../apps/apps-service'
import SelectToCopy from '../micro/select-to-copy'
import NetworkErrorBoundary from '../../lib/network-error-boundary'
import Fallback from '../micro/fallback'

const AppEventJson = ({event, device}) => {
  const deviceResult = device?.value()
  const [textElement, setTextElement] = useState(null)
  const data = useMemo(
    () => ({...deviceResult, ...event}),
    [deviceResult, event]
  )
  return (
    <>
      <div
        className="event-json-tree"
        css={{
          overflow: 'scroll',
          maxHeight: '40vh',
          border: `1px solid ${colors.gray100}`,
        }}
      >
        <JSONTree data={data} theme={jsonTreeTheme} hideRoot />
      </div>
      <Textarea
        value={JSON.stringify(data, null, 2)}
        onChange={_.noop}
        ref={setTextElement}
        css={{
          width: 0,
          height: 0,
          opacity: 0,
          padding: 0,
        }}
      />
      <SelectToCopy textElement={textElement} />
    </>
  )
}

// The hidden text area is required by the copy to clipboard feature
const AppEventModal = ({event, onClosed}) => {
  const device = useResourceOnce(args =>
    readDevice({deviceGuid: event.target.deviceId, ...args})
  )
  return (
    <Modal className="app-event-modal" onClosed={onClosed}>
      {({closeModal}) => (
        <Panel onClose={() => closeModal()}>
          <PanelHeader>{I18n.t('json_modal.header')}</PanelHeader>
          <NetworkErrorBoundary
            fallbackRender={() => <AppEventJson event={event} />}
          >
            <Suspense
              className="app-event-modal-pending"
              fallback={
                <Fallback className="app-event-modal-pending" spinner />
              }
            >
              <AppEventJson event={event} device={device} />
            </Suspense>
          </NetworkErrorBoundary>
          <PanelFooter>
            <Button onClick={() => closeModal()}>
              {I18n.t('json_modal.ok_button')}
            </Button>
          </PanelFooter>
        </Panel>
      )}
    </Modal>
  )
}

export default AppEventModal
