import React, {useState} from 'react'
import _ from 'lodash'
import {Badge, Table, tableStyles, typography} from '@lookout/ui'
import classes from '../../lib/utils/classes'
import {
  tableLayoutStyles,
  mobileResponsiveMargin,
} from '../../lib/styles/layout-styles'

const AppVersionsRow = ({appVersion, isExpanded}) => (
  <>
    {isExpanded &&
      appVersion.sdkVersions.map(sdkVersion => (
        <tr key={sdkVersion} className="expanded">
          <td className="app-version" css={typography.blush} />
          <td className="sdk-version">{sdkVersion}</td>
        </tr>
      ))}
  </>
)

const AppVersions = ({appVersions}) => {
  const [expandedVersion, setExpandedVersion] = useState(null)
  return (
    <>
      <h2
        css={{margin: 40, ...mobileResponsiveMargin}}
        className="version-history-heading"
      >
        {I18n.t('app_details.version_history_heading')}
      </h2>
      {appVersions.value().length ? (
        <Table
          className="versions-table"
          css={[
            tableStyles.striped,
            tableLayoutStyles.expandable,
            tableLayoutStyles.fullWidth,
            {
              'th, td': {
                minWidth: 200,
              },
            },
          ]}
        >
          <thead>
            <tr>
              <th>{I18n.t('app_details.app_version')}</th>
              <th>{I18n.t('app_details.sdk_version')}</th>
            </tr>
          </thead>
          <tbody>
            {appVersions.value().map(appVersion => {
              const isExpanded = appVersion.version === expandedVersion
              return (
                <>
                  <tr
                    key={appVersion.applicationGuid}
                    className={classes(
                      _.size(appVersion.sdkVersions) > 0 && 'expandable',
                      isExpanded && 'expanded'
                    )}
                    onClick={() =>
                      setExpandedVersion(isExpanded ? null : appVersion.version)
                    }
                  >
                    <td className="app-version">{appVersion.version}</td>
                    <td className="sdk-versions" css={{width: '100%'}}>
                      <Badge
                        className="sdk-versions-badge"
                        css={[{fontWeight: 600, fontSize: 11}]}
                      >
                        {appVersion.sdkVersions.length}{' '}
                        {I18n.t('app_details.versions', {
                          count: appVersion.sdkVersions.length,
                        })}
                      </Badge>
                    </td>
                  </tr>
                  <AppVersionsRow
                    appVersion={appVersion}
                    isExpanded={isExpanded}
                  />
                </>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <p
          className="app-versions-empty"
          css={{marginLeft: 40, marginRight: 40}}
        >
          {I18n.t('app_details.version_history.none')}
        </p>
      )}
    </>
  )
}

export default AppVersions
